import React from "react";
import { Main, Aside, Section } from "../../layout/index";
import { WPRender } from "../../components";
import "./Resources.css";

const Resources = () => {
  return (
    <Main slug="fluoridation" title="FLUORIDATION IN MARIN COUNTY">
      <Aside header="RESOURCES" showNav={false} active={1}></Aside>
      <Section>
        <WPRender postType="resources" slug="resources" />
      </Section>
    </Main>
  );
};

export default Resources;
