import useFetchBoard from "../../hooks/useFetchBoard";
import Spinner from "../../utils/Spinner/Spinner";

const Board = () => {
  const { loading, error, data } = useFetchBoard("/api/get_board.php");

  return (
    <div className="board">
      {loading && <Spinner />}
      {!loading &&
        !error &&
        data.length > 0 &&
        data.map((member) => {
          return (
            <div className="board-staff-box" key={member.ID}>
              <img
                src={`https://eu3.ragic.com/sims/file.jsp?a=cmbrinker&f=${member.Photo}`}
                alt={member.Name}
              />
              <div className="box-text">
                <h3>{member.Title}</h3>
                <h4>{member.Name}</h4>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default Board;
