const alphabetize = (data) => {
  let listArray = [];
  for (let i in data) listArray.push(data[i]);
  listArray.sort((a, b) => {
    if (a.last_name < b.last_name) return -1;
    if (a.last_name > b.last_name) return 1;
    return 0;
  });
  return listArray;
};

const searchList = (list, speciality, name, city) => {
  return list.filter((dr) => {
    if (speciality === "" && name === "" && city === "") return dr;
    return (
      dr.specialty.toLowerCase().includes(speciality) &&
      dr.city.toLowerCase().includes(city) &&
      dr.last_name.toLowerCase().includes(name.toLowerCase())
    );
  });
};

const searchListName = (list, name) => {
  return list.filter((dr) => {
    if (name === "") return dr;
    return dr.last_name.toLowerCase().includes(name.toLowerCase());
  });
};

export { alphabetize, searchList, searchListName };
