import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/logo/logo-green.png";
import styles from "./LandingPage.module.css";

const LandingPage = () => {
  return (
    <div className={styles.landing}>
      <Link to="/members">
        <div className={styles.members}>
          <h2 className={styles.membersTitle}>Members</h2>
        </div>
      </Link>
      <Link to="/guests">
        <div className={styles.guests}>
          <h2 className={styles.guestsTitle}>Guests</h2>
        </div>
      </Link>
      <div className={styles.logoWrapper}>
        <img src={Logo} alt="logo" className={styles.logo} />
      </div>
    </div>
  );
};

export default LandingPage;
