import React from "react";
import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {
  return (
    <section className="subpage-content privacy-policy">
      <h1 className="subpage-content-title">Privacy Policy</h1>
      <div className="privacy-policy-body">
        <p>
          The Marin County Dental Society is commited to protecting your privacy
          and does not sell, trade or rent your personal information to other
          entities.
        </p>
        <p>
          All rights are reserved by the Marin County Dental Society (MCDS).
          MCDS reserves the right to accept, edit or reject content. MCDS
          reserves the right to accept or reject advertisements or links. No
          material on the MCDS website can be reproduced on other Web sites
          without approval.
        </p>
        <p>
          MCDS does not endorse or guarantee the accuracy, completeness,
          efficacy, timliness, or correct sequencing of information contained on
          this Web site. Use of any information obtained via the MCDS Web site
          is voluntary, and reliance on it should only be undertaken after an
          independent review of its accuracy, complement, efficacy, and
          timeliness. Reference herein to any product, process, or services by
          trade name, trademark, service mark, manufacturer or other does not
          constitute or imply endorsement, recommendation, sponsorship, or
          favoring by MCDS.
        </p>
        <h2>Links to Other Websites</h2>
        <p>
          At certain places on this Web site, live "links" to other internet
          addresses can be accessed. Such external Internet addresses contain
          information created, published, maintained, or otherwise posted by
          institutions or organizations indepenent of MCDS. MCDS does not
          endorse or guarantee the accuracy, completeness, efficacy, timliness,
          or correct sequencing of information located at such addresses. Use of
          any information obtained from such addresses site is voluntary, and
          reliance on it should only be undertaken after an independent review
          of its accuracy, complement, efficacy, and timeliness. Reference
          herein to any product, process, or services by trade name, trademark,
          service mark, manufacturer or other does not constitute or imply
          endorsement, recommendation, sponsorship, or favoring by MCDS.
        </p>
      </div>
    </section>
  );
};

export default PrivacyPolicy;
