import React from "react";
import { Main, Aside, Section } from "../../../layout/index";
import { WPRender } from "../../../components";
import "./CERequirements.css";

const CERequirements = () => {
  const navList = [
    {
      label: "CE Requirements",
      value: "ce-requirements",
      key: 1,
    },
    {
      label: "CE Courses",
      value: "ce-courses",
      key: 2,
    },
    { label: "CPR Certification", value: "cpr-certification", key: 3 },
  ];

  return (
    <Main slug="ce-requirements" title="CONTINUING EDUCATION REQUIREMENTS">
      <Aside navList={navList} header="CE + CPR" active={1} />
      <Section>
        <WPRender postType="ce-cpr" slug="ce-requirements" />
      </Section>
    </Main>
  );
};

export default CERequirements;
