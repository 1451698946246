import React from "react";
import { Main, Aside, Section } from "../../../layout/index";
import Affiliates from "../../../components/Affiliates/Affiliates";
import BoardStaffLayout from '../../../layout/BoardStaffLayout/BoardStaffLayout';
import "./BoardAndStaff.css";

const BoardAndStaff = () => {
  const navList = [
    { label: "Board & Staff", value: "board-and-staff", key: 1 },
    { label: "History", value: "history", key: 2 },
  ];

  return (
    <Main
      slug="board-staff"
      title="BOARD AND STAFF"
      bodyClass="board-staff-subpage"
    >
      <Aside navList={navList} header="ABOUT US" active={1}>
        <div className="affiliates-wide-wrapper">
          <Affiliates />
        </div>
      </Aside>
      <Section>
        <BoardStaffLayout />
        <div className="affiliates-small-wrapper">
          <Affiliates />
        </div>
      </Section>
    </Main>
  );
};

export default BoardAndStaff;
