import React from "react";
import Spinner from "../../../../utils/Spinner/Spinner";
import PhoneIcon from "../../../../assets/icons/PhoneIcon";
import FaxIcon from "../../../../assets/icons/FaxIcon";
import Placeholder from "../../../../assets/placeholder.png";
import "./DentistList.css";

const DentistList = ({ paginatedList, loading }) => {
  return (
    <div className="dr-list-container">
      {loading && <Spinner addClass="spinner-border-top" />}
      {!loading && !paginatedList.length && (
        <>
          <h4>No Dentists found.</h4>
          <h4>Reset or try another search</h4>
        </>
      )}
      {!loading &&
        paginatedList.length > 0 &&
        paginatedList.map((dr) => {
          return (
            <div className="dr-box" key={`${dr.id}`}>
              <div className="dr-img-wrapper">
                {dr.Image && (
                  <img
                    src={`https://eu3.ragic.com/sims/file.jsp?a=cmbrinker&f=${dr.Image}`}
                    alt={`Dr ${dr.last_name}`}
                    className="dir-img"
                  />
                )}
                {!dr.Image && (
                  <img
                    src={Placeholder}
                    alt={`Dr ${dr.last_name}`}
                    className="dir-img"
                  />
                )}
              </div>
              <div className="name-speciality">
                <h3 className="name">
                  {`${dr.first_name} ${dr.last_name}, ${dr.title}`}
                </h3>
                <h4 className="speciality">{dr.specialty}</h4>
              </div>
              <p className="address">
                <span className="practice">{dr.practice}</span>
                {dr.practice ? <br /> : null}
                {dr.address}
                <br />
                {dr.address_2}
                {dr.address_2 ? <br /> : null}
                {`${dr.city ? `${dr.city}, ` : ""} `}{" "}
                {/* Prevent comma if null */}
                {dr.state} {dr.zipcode}
                {!dr.alt_address ? null : (
                  <span className="second-address">
                    <span className="practice">{dr.alt_practice}</span>
                    {dr.alt_practice ? <br /> : null}
                    {dr.alt_address}
                    <br />
                    {dr.address_2}
                    {dr.address_2 ? <br /> : null}
                    {`${dr.alt_city ? `${dr.alt_city}, ` : ""} `}{" "}
                    {/* Prevent comma if null */}
                    {dr.alt_state} {dr.alt_zipcode}
                  </span>
                )}
              </p>
              <div className="phone-website">
                <div className="phone-fax">
                  {dr.phone ? (
                    <p className="phone">
                      <PhoneIcon />{" "}
                      <a href={`tel:${dr.phone}`} rel="noopener noreferrer">
                        {dr.phone}
                      </a>
                    </p>
                  ) : null}
                  {dr.fax ? (
                    <p className="fax">
                      <FaxIcon /> {dr.fax}
                    </p>
                  ) : null}
                </div>
                <div className="website-wrapper">
                  {dr.website ? (
                    <span className="website">
                      <a
                        href={dr.website}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Website
                      </a>
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default DentistList;
