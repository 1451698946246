import React, { useState } from "react";
import axios from "axios";
import ADALogo from "../../../assets/ada-square-logo.png";
import CDALogo from "../../../assets/cda-square-logo.png";
import TDICLogo from "../../../assets/tdic-logo.png";
import "./HomeContact.css";

const HomeContact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [mailSent, setMailSent] = useState(false);
  const [error, setError] = useState(null);

  const contactAPIPath = "/api/contact.php";

  const resetForm = () => {
    setName("");
    setEmail("");
    setMessage("");
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setError(null);
    if (name === "" || email === "" || message === "") {
      setError("All fields are required.");
      return;
    }
    axios({
      method: "post",
      url: contactAPIPath,
      headers: { "content-type": "application/json" },
      data: {
        name: name,
        email: email,
        message: message,
      },
    })
      .then((result) => {
        setMailSent(true);
        resetForm();
      })
      .catch((error) => setError(error.message));
  };

  return (
    <section className="home-contact">
      <div className="home-contact-content">
        <div className="home-contact-affiliates">
          <h3>Proud Member</h3>
          <a
            href="https://www.cda.org/"
            rel="noreferrer noopener"
            target="_blank"
          >
            <img src={CDALogo} alt="CDA logo" className="cda-logo" />
          </a>
          <a
            href="https://www.ada.org/"
            rel="noreferrer noopener"
            target="_blank"
          >
            <img src={ADALogo} alt="ADA logo" className="ada-logo" />
          </a>
          <a
            href="https://www.tdicinsurance.com/"
            rel="noreferrer noopener"
            target="_blank"
          >
            <img src={TDICLogo} alt="TDIC logo" className="tdic-logo" />
          </a>
        </div>
        <div className="contact-info">
          <h3>Contact</h3>
          <address>
            175 N Redwood Drive Suite 130
            <br />
            San Rafael, CA 94903
          </address>
          <p>
            <a href="tel:4154727974">Public: 415-472-7974</a>
            <br />
            Members: <a href="tel:4154727973">415-472-7973</a>
            <br />
            Fax: 415-472-7894
          </p>
        </div>

        <div className="contact-form-container">
          <h3 className="contact-subtitle">Send Us A Message</h3>
          {!mailSent ? (
            <form action="#" id="contact-form">
              <input
                type="text"
                id="name"
                placeholder="Name"
                onChange={(e) => setName(e.target.value)}
                value={name}
                required
              />
              <input
                type="email"
                id="email"
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                required
              />
              <textarea
                id="message"
                placeholder="Message"
                onChange={(e) => setMessage(e.target.value)}
                value={message}
                required
              />
              <input
                type="submit"
                value="Send"
                id="send"
                onClick={(e) => handleFormSubmit(e)}
              />
            </form>
          ) : (
            <span>Thank you for your message.</span>
          )}
          {!error ? null : <span>{error}</span>}
        </div>
      </div>
    </section>
  );
};

export default HomeContact;
