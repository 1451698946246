import React from "react";
import { Main, Section, Aside } from "../../../layout/index";
import Applications from "../../../components/Applications/Applications";
import { WPRender } from "../../../components";
import "./Leadership.css";

const Leadership = () => {
  const navList = [
    { label: "Join/Renew", value: "join-renew", key: 1 },
    { label: "Member Benefits", value: "member-benefits", key: 2 },
    { label: "Member Directory", value: "member-directory", key: 4 },
    { label: "Leadership", value: "leadership", key: 5 },
    { label: "Emergency Panel", value: "emergency-panel", key: 6 },
  ];

  return (
    <Main slug="leadership" title="LEADERSHIP">
      <Aside header="MEMBERSHIP" navList={navList} active={5}>
        <Applications />
      </Aside>
      <Section>
        <WPRender postType="membership" slug="leadership" />
      </Section>
    </Main>
  );
};

export default Leadership;
