import React from "react";
import "./MakePost.css";

const MakePost = () => {
  return (
    <div className="make-post-sidebar">
      <h3>Want to Add Your Listing?</h3>
      <p>Members and Guests are Welcome</p>
      <a href="https://posts.marindentalsociety.org">
        <button className="make-post-btn">Submit a Post</button>
      </a>
    </div>
  );
};

export default MakePost;
