import React from "react";
import { Link } from "react-router-dom";
import FindDentist from "../../../assets/banner-boxes/find-dentist.png";
import CECPR from "../../../assets/banner-boxes/ce-cpr.png";
import JoinRenew from "../../../assets/banner-boxes/join-renew.png";
import Classifieds from "../../../assets/banner-boxes/classifieds.png";

// clean up CSS issues between 2 banner boxes

const BannerBoxesGuest = () => {
  return (
    <div className="banner-boxes">
      <div className="banner-box">
        <h3 className="box-title">Find a Dentist</h3>
        <img
          src={FindDentist}
          alt="dental office"
          className="banner-box-icon"
        />
        <p>Search our members to find the right dentist for you.</p>
        <Link to="/find-a-dentist" className="box-btn">
          Find Your Match
        </Link>
      </div>

      <div className="banner-box">
        <h3 className="box-title">Dental Care Foundation</h3>
        <img src={CECPR} alt="computer" className="banner-box-icon" />
        <p>We work to make a difference in our community.</p>
        <Link to="/foundation" className="box-btn">
          View Foundation
        </Link>
      </div>
      <div className="banner-box">
        <h3 className="box-title">Advertising / Marketing</h3>
        <img src={JoinRenew} alt="dentist" className="banner-box-icon" />
        <p>Explore opportunities to partner with us.</p>
        <Link to="/advertising" className="box-btn">
          Let's Collaborate
        </Link>
      </div>
      <div className="banner-box">
        <h3 className="box-title">Classifieds</h3>
        <img
          src={Classifieds}
          alt="page with tooth"
          className="banner-box-icon"
        />
        <p>Explore employment opportunities and practices for sale.</p>
        <Link to="employment-opportunities" className="box-btn">
          See Our Listings
        </Link>
      </div>
    </div>
  );
};

export default BannerBoxesGuest;
