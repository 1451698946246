import React from "react";
import parse from "html-react-parser";
import { Spinner } from "../../../utils";
import { convertDate } from "../../../utils";
import styles from "./EventsList.module.css";

const EventsList = ({ events, loading }) => {
  return (
    <>
      {loading && <Spinner />}
      {!events && <p>Currently there are no events</p>}
      {events?.map((event) => (
        <div className={styles.box} key={event.id}>
          <a href={`${event.link}`}>
            <h4 className={styles.title}>{parse(event.title.rendered)}</h4>
          </a>
          <p className={styles.date}>{convertDate(event._event_start_date)}</p>
        </div>
      ))}
    </>
  );
};

export default EventsList;
