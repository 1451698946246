import React from "react";
import Loader from "react-loader-spinner";
import "./Spinner.css";

const Spinner = ({ type = "TailSpin", timeout = 5000, addClass }) => {
  return (
    <div className={`spinner-wrapper ${addClass}`}>
      <Loader
        type={type}
        color="#337e86"
        height={50}
        width={50}
        timeout={timeout}
        className="spinner"
      />
    </div>
  );
};

export default Spinner;
