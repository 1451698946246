import { useEffect, useState } from "react";
import axios from "axios";
import * as Sentry from "@sentry/react";
import { sortDates, getFutureEvents } from "../utils";

const useFetch = (url, limit) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  //Abort is a cleanup to prevent a memory leak in an unmounting component

  //Event url must include 'per_page=99' in order to get as many events
  //as possible to be sliced later since Wordpress delivers events by
  //created by date NOT by event date

  useEffect(() => {
    let abort = false;
    setLoading(true);
    axios
      .get(url)
      .then((res) => {
        if (abort) return;
        let sortedData = getFutureEvents(sortDates(res.data));
        setData(sortedData.slice(0, limit - 1));
      })
      .catch((err) => {
        setError(true);
        Sentry.captureException(err);
      })
      .finally(() => setLoading(false));
    return () => (abort = true);
  }, [url, limit]);

  return { loading, error, data };
};

export default useFetch;
