import React from "react";
import styles from "./Article.module.css";

const Article = ({ children, bodyClass }) => {
  return (
    <article className={`${styles.article} ${bodyClass}`}>
      {/* <section className={`subpage-body ${!bodyClass ? "" : bodyClass}`}> */}
      {children}
    </article>
  );
};

export default Article;
