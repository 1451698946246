const Email = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -32 512 511">
      <path d="M504.965 28.75L295.012 238.703c-10.758 10.758-24.883 16.133-39.012 16.133s-28.254-5.375-39.008-16.133L7.035 28.75A54.86 54.86 0 000 55.664v337.402c0 30.418 24.746 55.168 55.168 55.168h401.668c30.418 0 55.164-24.75 55.164-55.168V55.664a54.86 54.86 0 00-7.035-26.914zm0 0"></path>
      <path d="M273.797 217.492L483.75 7.535A54.829 54.829 0 00456.836.5H55.168c-9.77 0-18.95 2.563-26.918 7.035l209.953 209.957c9.817 9.813 25.781 9.813 35.594 0zm0 0"></path>
    </svg>
  );
};

export default Email;
