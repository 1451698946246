import React from "react";
import { Main, Aside, Section } from "../../../layout/index";
import Affiliates from "../../../components/Affiliates/Affiliates";
import LogoGreen from "../../../assets/logo/logo-green.png";
import { WPRender } from "../../../components";
import "./History.css";

const History = () => {
  const navList = [
    { label: "Board & Staff", value: "board-and-staff", key: 1 },
    { label: "History", value: "history", key: 2 },
  ];

  return (
    <Main slug="history" title="MCDS HISTORY" bodyClass="history-subpage">
      <Aside navList={navList} header="ABOUT US" active={2}>
        <div className="affiliates-wide-wrapper">
          <Affiliates />
        </div>
      </Aside>
      <Section>
        <WPRender postType="about" slug="history" />
        <div className="affiliates-small-wrapper">
          <Affiliates />
        </div>
      </Section>
    </Main>
  );
};

export default History;
