import styles from "./Mobile.module.css";

const GuestsNav = ({ handleClick }) => {
  return (
    <ul className={styles.navList} onClick={(e) => handleClick(e)}>
      <li data-path="/guests">HOME</li>
      <li id="about-us">{"ABOUT >"}</li>
      <li data-path="/find-a-dentist">FIND A DENTIST</li>
      <li data-path="/foundation">MCDCF</li>
      <li data-path="/advertising">ADVERTISING</li>
      <li id="classifieds">{"CLASSIFIEDS >"}</li>
      <li data-path="/fluoridation">FLUORIDATION</li>
      <li data-path="/childrens-corner">CHILDREN'S CORNER</li>
      <li data-path="/emergencies">EMERGENCIES</li>
      <li data-path="/members" className={styles.switch}>
        Switch to Member
      </li>
    </ul>
  );
};

export default GuestsNav;
