import useFetch from "../../../hooks/useFetch";
import Spinner from "../../../utils/Spinner/Spinner";
import parse from "html-react-parser";
import styles from "./Sponsors.module.css";

const Sponsors = () => {
  const { data, loading, error } = useFetch(
    "https://posts.marindentalsociety.org/wp-json/wp/v2/sponsors?slug=sponsors"
  );

  return (
    <section className={styles.sponsors}>
      <h2 className={styles.title}>Sponsors</h2>
      <div className={styles.container}>
        {loading ? <Spinner /> : parse(data[0].content.rendered)}
      </div>
    </section>
  );
};

export default Sponsors;
