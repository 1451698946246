import React from "react";
import BrokenTooth from "../../assets/icons/broken-tooth.svg";
import styles from "./ErrorMessage.module.css";

const ErrorMessage = () => {
  return (
    <div className={styles.errorMessage}>
      <img src={BrokenTooth} alt="broken tooth" style={{ width: "45px" }} />
      <p>
        Something went wrong
        <br />
        Please try again later
      </p>
    </div>
  );
};

export default ErrorMessage;
