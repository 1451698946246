import { convertDate } from "../../../utils/convertDateTime";
import parse from "html-react-parser";
import Spinner from "../../../utils/Spinner/Spinner";
import "./NewsItem.css";

const NewsItem = ({ loadingPost, activePost }) => {
  return (
    <section className="news-item">
      {loadingPost && <Spinner />}
      {!loadingPost && (
        <div className="news-item-container">
          <h2 className="title">{parse(activePost.title.rendered)}</h2>
          <div className="date">Posted {convertDate(activePost.date)}</div>
          {activePost._embedded && (
            <img
              src={activePost._embedded["wp:featuredmedia"][0].source_url}
              alt="news-banner"
              className="img"
            />
          )}
          <div className="excerpt">{parse(activePost.excerpt.rendered)}</div>
          {parse(activePost.content.rendered)}
        </div>
      )}
    </section>
  );
};

export default NewsItem;
