import React, { useEffect, useState } from "react";
import axios from "axios";
import Spinner from "../../../utils/Spinner/Spinner";
import useSmallScreen from "../../../hooks/useSmallScreen";
import { searchListName } from "../../../utils/sortDentists";
import { Main, Aside, Section } from "../../../layout/index";
import Applications from "../../../components/Applications/Applications";
import Pagination from "../../../utils/Pagination";
import Placeholder from "../../../assets/placeholder.png";
import "./MemberDirectory.css";

const MemberDirectory = () => {
  const [loading, setLoading] = useState();
  const [drList, setDrList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(30);
  const [name, setName] = useState("");
  const [searchedList, setSearchedList] = useState([]);

  const { smallScreen } = useSmallScreen(1030);

  useEffect(() => {
    setLoading(true);
    const fetchDoctors = () => {
      axios
        .get("/api/get_members.php")
        .then((res) => {
          let listArray = [];
          for (let i in res.data) listArray.push(res.data[i]);
          listArray.sort((a, b) => {
            if (a.last_name < b.last_name) return -1;
            if (a.last_name > b.last_name) return 1;
            return 0;
          });
          setDrList(listArray);
        })
        .then(() => setLoading(false));
    };

    fetchDoctors();
    setCurrentPage(1);
  }, []);

  useEffect(() => {
    setSearchedList(searchListName(drList, name));
    setCurrentPage(1);
  }, [drList, name]);

  const navList = [
    { label: "Join/Renew", value: "join-renew", key: 1 },
    { label: "Member Benefits", value: "member-benefits", key: 2 },
    { label: "Member Directory", value: "member-directory", key: 3 },
    { label: "Leadership", value: "leadership", key: 5 },
    { label: "Emergency Panel", value: "emergency-panel", key: 6 },
  ];

  //Adjust index of last post on last page if not a multiple of postsPerPage
  let indexOfLastPost = postsPerPage * currentPage;
  let indexOfFirstPost = indexOfLastPost - postsPerPage;

  indexOfLastPost =
    indexOfLastPost > searchedList.length
      ? searchedList.length
      : indexOfLastPost;

  let paginatedList = searchedList.slice(indexOfFirstPost, indexOfLastPost);

  return (
    <Main slug="directory" title="MEMBER DIRECTORY">
      <Aside navList={navList} header="MEMBERSHIP" active={3}>
        <h3 className="member-search">Search Members</h3>
        <form action="" className="name-form">
          <input
            type="text"
            id="last_name"
            placeholder=" Last Name"
            className="name-input"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </form>
        <p
          className="member-reset-btn"
          onClick={() => {
            setName("");
          }}
        >
          Reset
        </p>
        {smallScreen ? null : <Applications />}
      </Aside>
      <Section>
        <Pagination
          loading={loading}
          numberOfPosts={searchedList.length}
          currentPage={currentPage}
          postsPerPage={postsPerPage}
          selectPage={(e) => setCurrentPage(+e.target.dataset.key)}
          indexOfFirstPost={indexOfFirstPost}
          indexOfLastPost={indexOfLastPost}
          propClass="directory-pagination-top"
        />
        {loading ? (
          <Spinner />
        ) : (
          <div className="directory-container">
            {paginatedList.map((dr) => {
              return (
                <div className="directory-box" key={dr.id}>
                  {dr.Image && (
                    <img
                      src={`https://eu3.ragic.com/sims/file.jsp?a=cmbrinker&f=${dr.Image}`}
                      alt={`Dr ${dr.last_name}`}
                      className="dir-img"
                    />
                  )}
                  {!dr.Image && (
                    <img
                      src={Placeholder}
                      alt={`Dr ${dr.last_name}`}
                      className="dir-img"
                    />
                  )}
                  <h3 className="dir-name">{`${dr.last_name}, ${dr.first_name}`}</h3>
                  <h5 className="dir-specialty">{dr.specialty}</h5>
                  {dr.Retired === "Yes" && <h5>Retired</h5>}
                  <div className="dir-text">
                    {!dr.practice ? null : (
                      <>
                        <span className="dir-practice">
                          {dr.practice} <br />
                        </span>{" "}
                      </>
                    )}
                    {dr.address ? (
                      <>
                        {dr.address}
                        <br />
                      </>
                    ) : null}
                    {!dr.address_2 ? null : (
                      <>
                        {dr.address_2}
                        <br />
                      </>
                    )}
                    {`${dr.city ? `${dr.city}, ` : ""} `} {dr.state}{" "}
                    {dr.zipcode}
                    {!dr.alt_address ? null : (
                      <>
                        <br />
                        <br />
                        {dr.alt_practice}
                        {dr.alt_practice ? <br /> : null}
                        {dr.alt_address}
                        <br />
                        {dr.address_2}
                        {dr.address_2 ? <br /> : null}
                        {`${dr.alt_city ? `${dr.alt_city}, ` : ""} `}{" "}
                        {dr.alt_state} {dr.alt_zipcode}
                      </>
                    )}
                    {dr.phone ? (
                      <>
                        <br />
                        <br />
                        <span>
                          Phone:{" "}
                          <a href={`tel:${dr.phone}`} rel="noopener noreferrer">
                            {dr.phone}
                          </a>
                        </span>
                        {dr.fax ? (
                          <>
                            <br />
                            <span>Fax: {dr.fax}</span>
                          </>
                        ) : null}
                      </>
                    ) : null}
                    {dr.website ? (
                      <>
                        <a
                          href={dr.website}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="dir-website"
                        >
                          View Website
                        </a>
                      </>
                    ) : null}
                    {dr.Email ? (
                      <>
                        <a
                          href={`mailto:${dr.Email}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="dir-website"
                        >
                          {dr.Email}
                        </a>
                      </>
                    ) : null}
                  </div>
                </div>
              );
            })}
          </div>
        )}
        <Pagination
          loading={loading}
          numberOfPosts={searchedList.length}
          currentPage={currentPage}
          postsPerPage={postsPerPage}
          selectPage={(e) => setCurrentPage(+e.target.dataset.key)}
          indexOfFirstPost={indexOfFirstPost}
          indexOfLastPost={indexOfLastPost}
          propClass="directory-pagination-bottom"
        />
      </Section>
    </Main>
  );
};

export default MemberDirectory;
