import { useHistory } from "react-router-dom";
import { convertDate } from "../../../utils";
import parse from "html-react-parser";
import useFetchEvents from "../../../hooks/useFetchEvents";
import Spinner from "../../../utils/Spinner/Spinner";
import ErrorMessage from "../../../components/ErrorMessage/ErrorMessage";
import styles from "./events.module.css";

const Events = () => {
  const history = useHistory();

  const { data, loading, error } = useFetchEvents(
    "https://posts.marindentalsociety.org/wp-json/wp/v2/event?per_page=20",
    5
  );

  return (
    <section className={styles.container}>
      <h2 className={styles.heading}>Upcoming Events</h2>
      {error && <ErrorMessage />}
      {!error && loading && <Spinner />}
      {!error && !loading && (
        <div className={styles.eventsContainer}>
          {!data.length ? (
            <h3 className="nothing">
              There are no events scheduled at this time
            </h3>
          ) : (
            data.map((event) => {
              return (
                <div className={styles.event} key={event.id}>
                  <h4 className={styles.summary}>
                    {parse(event.title.rendered)}
                  </h4>
                  <p className={styles.date}>
                    {convertDate(event._event_start_date)}
                  </p>
                  <a className={styles.button} href={event.link}>
                    See Event
                  </a>
                </div>
              );
            })
          )}
        </div>
      )}
      <button
        className={styles.linkButton}
        onClick={() => history.push("/members/calendar")}
      >
        View Full Calendar
      </button>
    </section>
  );
};

export default Events;
