import { useEffect, useState } from "react";
import axios from "axios";
import * as Sentry from "@sentry/react";

const useFetchBoard = (url) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  //Abort is a cleanup to prevent a memory leak in an unmounting component
  //Items are sorted ascending by ID

  useEffect(() => {
    let abort = false;
    setLoading(true);
    let boardArray = [];
    axios
      .get(url)
      .then((res) => {
        if (abort) return;
        for (let i in res.data) boardArray.push(res.data[i]);
        boardArray.sort((a, b) => {
          if (a.ID < b.ID) return -1;
          if (a.ID > b.ID) return 1;
          return 0;
        });
        setData(boardArray);
      })
      .catch((err) => {
        setError(true);
        Sentry.captureException(err);
      })
      .finally(() => setLoading(false));
    return () => (abort = true);
  }, [url]);

  return { loading, error, data };
};

export default useFetchBoard;
