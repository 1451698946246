import { forwardRef } from "react";
import ArrowBack from "../../../assets/icons/header/ArrowBack";
import styles from "./Mobile.module.css";

const SubList = forwardRef((props, ref) => {
  return (
    <ul className={styles.subList} ref={ref}>
      <li className={styles.back} onClick={() => props.handleBack()}>
        <ArrowBack />
        Back
      </li>
      {props.subList.map((item) => (
        <li
          key={item.slug}
          data-path={`${props.members ? "/members/" : "/"}${item.slug}`}
          onClick={(e) => props.handleClick(e)}
        >
          {item.name}
        </li>
      ))}
    </ul>
  );
});

export default SubList;
