import OpenInNew from "../../assets/icons/sidebar/OpenInNew";
import PDFIcon from "../../assets/icons/sidebar/PDFIcon";
import styles from "./Applications.module.css";

const Applications = () => {
  return (
    <div className={styles.container}>
      <div className={styles.renewContainer}>
        <h3 className={styles.title}>Renew</h3>
        <a
          href="https://www.cda.org/login"
          rel="noopener noreferrer"
          target="_blank"
        >
          Renew Online
          <OpenInNew styles={styles.openInNew} />
        </a>
      </div>
      <div className={styles.memberContainer}>
        <h3 className={styles.title}>Apply</h3>
        <div className={styles.memberApplication}>
          <h4 className={styles.subTitle}>Dentists</h4>
          <a
            href="https://ebusiness.cda.org/ebusiness/NewUser"
            rel="noopener noreferrer"
            target="_blank"
            className={styles.apply}
          >
            Apply Online
            <OpenInNew styles={styles.openInNew} />
          </a>
        </div>
        <div className={styles.memberApplication}>
          <h4 className={styles.subTitle}>Dental Professionals</h4>
          <a href="/mcds-adhp-membership.pdf" download>
            <PDFIcon styles={styles.pdf} /> Download
          </a>
        </div>
      </div>
    </div>
  );
};

export default Applications;
