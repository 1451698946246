const Login = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      className="login"
    >
      <path d="M21.817 15.475A7.964 7.964 0 0024 10c0-4.411-3.589-8-8-8s-8 3.589-8 8c0 2.119.834 4.042 2.183 5.475C6.185 17.706 4 22.432 4 29a1 1 0 001 1h22a1 1 0 001-1c0-6.569-2.185-11.294-6.183-13.525zM16 4c3.309 0 6 2.691 6 6s-2.691 6-6 6-6-2.691-6-6 2.691-6 6-6zM6.018 28c.157-4.234 1.388-9.193 5.883-11.143 1.2.721 2.6 1.143 4.099 1.143s2.899-.422 4.099-1.143c4.494 1.949 5.726 6.909 5.882 11.143z"></path>
    </svg>
  );
};

export default Login;
