import styles from "./Mobile.module.css";

const MembersNav = ({ handleClick }) => {
  return (
    <ul className={styles.navList} onClick={(e) => handleClick(e)}>
      <li data-path="/members">HOME</li>
      <li id="membership">{"MEMBERSHIP >"}</li>
      <li id="ce-cpr">{"CE/CPR >"}</li>
      <li data-path="/members/calendar">CALENDAR</li>
      <li data-path="/members/news">NEWS</li>
      <li id="classifieds">{"CLASSIFIEDS >"}</li>
      <li data-path="/members/advertising">ADVERTISING</li>
      <li data-path="/members/foundation">FOUNDATION</li>
      <li data-path="/members/resources">RESOURCES</li>
      <li id="about-us">{"ABOUT US >"}</li>
      <li>
        <a href="https://posts.marindentalsociety.org">Member Login</a>
      </li>
      <li data-path="/guests" className={styles.switch}>
        Switch to Guest
      </li>
    </ul>
  );
};

export default MembersNav;
