import useFetch from "../../hooks/useFetch";
import Spinner from "../../utils/Spinner/Spinner";
import parse from "html-react-parser";
import ErrorMessage from "../ErrorMessage/ErrorMessage";

const WPRender = ({ postType, slug }) => {
  const { data, loading, error } = useFetch(
    `https://posts.marindentalsociety.org/wp-json/wp/v2/${postType}?slug=${slug}`
  );

  return (
    <>
      {error && <ErrorMessage />}
      {!error && loading && <Spinner />}
      {!error && !loading && !data[0]?.content && (
        <p>There is no content at this time</p>
      )}
      {!error && !loading && parse(data[0]?.content.rendered)}
    </>
  );
};
export default WPRender;
