import React from "react";
import { Main, Aside, Section } from "../../../layout/index";
import { WPRender, EventsTable } from "../../../components";
import "./CPRCertification.css";

const CPRCertification = () => {
  const navList = [
    {
      label: "CE Requirements",
      value: "ce-requirements",
      key: 1,
    },
    {
      label: "CE Courses",
      value: "ce-courses",
      key: 2,
    },
    { label: "CPR Certification", value: "cpr-certification", key: 3 },
  ];

  return (
    <Main slug="cpr" title="CPR CERTIFICATION REQUREMENTS">
      <Aside navList={navList} header="CE + CPR" active={3} />
      <Section>
        <WPRender postType="ce-cpr" slug="cpr-certification" />
        <EventsTable categoryID="24" />
      </Section>
    </Main>
  );
};

export default CPRCertification;
