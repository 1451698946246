import React from "react";
import { Main, Section, Aside } from "../../../layout/index";
import { WPRender } from "../../../components/";
import SeasonTicket from "../../../components/SeasonTicket/SeasonTicket";
import { EventsTable } from "../../../components/";

const CECourses = () => {
  const navList = [
    {
      label: "CE Requirements",
      value: "ce-requirements",
      key: 1,
    },
    {
      label: "CE Courses",
      value: "ce-courses",
      key: 2,
    },
    { label: "CPR Certification", value: "cpr-certification", key: 3 },
  ];

  return (
    <Main slug="ce-courses" title="CONTINUING EDUCATION COURSES">
      <Aside header="CE + CPR" navList={navList} active={2}>
        <SeasonTicket />
      </Aside>
      <Section>
        <WPRender postType="ce-cpr" slug="ce-courses" />
        <EventsTable categoryID="22" />
      </Section>
    </Main>
  );
};

export default CECourses;
