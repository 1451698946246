import React from "react";

const PhoneIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      x="0"
      y="0"
      fill="#1A1A1A"
      enableBackground="new 0 0 100 100"
      viewBox="0 0 100 100"
      id="phone-icon"
    >
      <path d="M57.46 5.023a2.001 2.001 0 00.149 3.998c8.676-.353 17.331 3.177 23.762 9.607 6.431 6.432 9.933 15.092 9.608 23.762a2 2 0 001.924 2.074l.076.001a2 2 0 001.997-1.925c.366-9.778-3.562-19.525-10.777-26.74S67.232 4.655 57.46 5.023z"></path>
      <path d="M71.436 28.564c3.548 3.548 5.622 8.223 5.997 13.52a2 2 0 103.991-.284c-.445-6.271-2.92-11.826-7.159-16.064-4.239-4.239-9.793-6.715-16.064-7.159a2.007 2.007 0 00-2.136 1.854 2 2 0 001.854 2.137c5.294.374 9.968 2.449 13.517 5.996zM80.33 60.771c-2.198-2.198-5.12-3.408-8.228-3.408s-6.03 1.21-8.228 3.408l-3.576 3.575c-1.657 1.657-4.282 1.866-6.241.496a76.97 76.97 0 01-10.273-8.628 76.995 76.995 0 01-8.627-10.272c-1.37-1.959-1.162-4.584.496-6.241l3.575-3.576c4.537-4.536 4.537-11.918 0-16.454l-8.08-8.08a11.56 11.56 0 00-8.228-3.408 11.56 11.56 0 00-8.227 3.408l-3.684 3.683c-4.558 4.56-6.725 10.96-5.797 17.121 1.278 8.489 4.437 17.164 9.131 25.08 3.369 5.713 7.516 11.047 12.325 15.856 4.808 4.808 10.142 8.954 15.852 12.322 7.921 4.697 16.595 7.855 25.083 9.133.941.142 1.887.212 2.832.212 5.242 0 10.428-2.146 14.29-6.01l3.683-3.683a11.556 11.556 0 003.408-8.228c0-3.107-1.21-6.029-3.408-8.228l-8.078-8.078zm5.251 21.707l-3.684 3.683c-3.664 3.666-8.784 5.411-13.697 4.671-7.984-1.202-16.158-4.183-23.644-8.621-5.423-3.199-10.49-7.138-15.06-11.707-4.571-4.571-8.51-9.638-11.71-15.064-4.436-7.48-7.416-15.655-8.619-23.64-.739-4.911 1.007-10.032 4.671-13.697l3.683-3.683c2.978-2.977 7.821-2.977 10.798 0l8.08 8.08c2.977 2.977 2.977 7.821 0 10.798l-3.575 3.576c-3.032 3.031-3.429 7.811-.945 11.362a81.042 81.042 0 009.076 10.808l1.415-1.414-1.414 1.414a80.942 80.942 0 0010.809 9.077c3.553 2.485 8.332 2.087 11.362-.945l3.576-3.575c1.442-1.442 3.36-2.236 5.399-2.236s3.957.794 5.399 2.236l8.08 8.079a7.586 7.586 0 012.236 5.399 7.588 7.588 0 01-2.236 5.399z"></path>
    </svg>
  );
};

export default PhoneIcon;
