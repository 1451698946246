import { Link } from "react-router-dom";
import Login from "../../../assets/icons/header/Login";
import styles from "./ToggleVersion.module.css";

const ToggleVersion = ({ members }) => {
  return (
    <div className={styles.container}>
      {members ? (
        <div className={styles.loginWrapper}>
          <Login />
          <a
            href="https://posts.marindentalsociety.org"
            className={styles.loginLink}
          >
            Members Login
          </a>
        </div>
      ) : null}
      <div className={styles.version}>
        <Link to="/members" className={`${members && styles.versionActive}`}>
          Members
        </Link>{" "}
        <span className={styles.pipe}>|</span>{" "}
        <Link to="/guests" className={`${!members && styles.versionActive}`}>
          Guests
        </Link>
      </div>
    </div>
  );
};

export default ToggleVersion;
