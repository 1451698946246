import useFetchEvents from "../../hooks/useFetchEvents";
import { convertDate, convertTimeTo12, Spinner } from "../../utils";
import parse from "html-react-parser";
import styles from "./EventsTable.module.css";

const EventsTable = ({ categoryID }) => {
  const url = `https://posts.marindentalsociety.org/wp-json/wp/v2/event?per_page=99&event-categories=${categoryID}`;

  const { data, loading, error } = useFetchEvents(url, 10);

  return (
    <>
      {error && loading && <Spinner />}
      {!error && loading && <Spinner />}
      {!error && !loading && !data.length && (
        <p>There are no scheduled events</p>
      )}
      {!error && !loading && data.length > 0 && (
        <table className={styles.table}>
          <thead className={styles.head}>
            <tr>
              <th>Name</th>
              <th>Date</th>
              <th>Time</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((event) => {
              return (
                <tr key={event.id}>
                  <td className={styles.title}>
                    {parse(event.title.rendered)}
                  </td>
                  <td>{convertDate(event._event_start_date)}</td>
                  <td>{convertTimeTo12(event._event_start_time)}</td>
                  <td>
                    <a href={event.link} className={styles.register}>
                      See Event
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </>
  );
};

export default EventsTable;
