import React from "react";
import { Main, Section, Aside } from "../../layout/index";
import { WPRender } from "../../components";

const ChildrensCorner = () => {
  return (
    <Main slug="childrens-corner" title="CHILDREN'S CORNER">
      <Aside header="CHILDREN'S CORNER" showNav={false} active={1}></Aside>
      <Section>
        <WPRender postType="childrens-corner" slug="childrens-corner" />
      </Section>
    </Main>
  );
};

export default ChildrensCorner;
