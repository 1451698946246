import React from "react";
import { Main, Section, Aside } from "../../layout/index";
import { WPRender } from "../../components";

const Fluoridation = () => {
  return (
    <Main slug="fluoridation" title="FLUORIDATION IN MARIN COUNTY">
      <Aside header="FLUORIDATION" showNav={false} active={1}></Aside>
      <Section>
        <WPRender postType="fluoridation" slug="fluoridation" />
      </Section>
    </Main>
  );
};

export default Fluoridation;
