//This function compares the current date with date of each event in an array
//and returns only those dates that occur today or in the future

const today = new Date()
  //fr-CA is used to derive the YYYY-MM-DD format for comparison with _event_start_date
  //which is in that same format
  .toLocaleDateString("fr-CA", {
    timeZone: "UTC",
    year: "numeric",
    month: "numeric",
    day: "numeric",
  })
  .replace(/-/gi, "");

const getFutureEvents = (array) => {
  return array.filter(
    (event) => event._event_start_date.replace(/-/gi, "") >= today
  );
};

export { getFutureEvents };
