import React from "react";
import { Main, Section, Aside } from "../../layout/index";
import { WPRender } from "../../components";

const Emergencies = () => {
  return (
    <Main slug="cpr" title="EMERGENCIES">
      <Aside header="EMERGENCIES" showNav={false} active={1}></Aside>
      <Section>
        <WPRender postType="emergencies" slug="emergencies" />
      </Section>
    </Main>
  );
};

export default Emergencies;
