import { useHistory } from "react-router";
import "./Aside.css";

const Aside = ({
  showHeader = true,
  showNav = true,
  header,
  navList,
  active,
  children,
}) => {
  let history = useHistory();

  return (
    <aside className="sidebar">
      {showHeader && <h3 className="sidebar-header">{header}</h3>}
      {showNav ? (
        <div className="sidenav-container">
          <ul className="sidenav">
            {navList.map((listItem) => (
              <li
                key={listItem.key}
                className={listItem.key === active ? "active" : null}
                onClick={() => history.push(`${listItem.value}`)}
              >
                {listItem.label}
              </li>
            ))}
          </ul>
        </div>
      ) : null}
      {children}
    </aside>
  );
};

export default Aside;
