import React, { useState, useEffect } from "react";
import useFetch from "../../../hooks/useFetch";
import { alphabetize, searchList } from "../../../utils/sortDentists";
import { Main, Aside, Section } from "../../../layout/index";
import DentistList from "./DentistList/DentistList";
import Pagination from "../../../utils/Pagination";
import SearchDentists from "./SearchDentists/SearchDentists";
import "./FindADentist.css";

const FindADentist = () => {
  const [sortedList, setSortedList] = useState([]);
  const [speciality, setSpeciality] = useState("");
  const [city, setCity] = useState("");
  const [name, setName] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(30);

  const { data, loading } = useFetch("/api/get_dentists.php");

  useEffect(() => {
    setSortedList(searchList(alphabetize(data), speciality, name, city));
    setCurrentPage(1);
  }, [data, speciality, city, name]);

  let indexOfLastPost = postsPerPage * currentPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  //Adjust index of last post on last page if not a multiple of postsPerPage
  indexOfLastPost =
    indexOfLastPost > sortedList.length ? sortedList.length : indexOfLastPost;

  const paginatedList = sortedList.slice(indexOfFirstPost, indexOfLastPost);

  return (
    <Main slug="directory" title="FIND A DENTIST">
      <Aside showHeader={false} showNav={false}>
        <SearchDentists
          speciality={speciality}
          selectSpeciality={(specialty) => setSpeciality(specialty)}
          city={city}
          selectCity={(city) => setCity(city)}
          name={name}
          searchName={(name) => setName(name)}
          reset={() => {
            setSpeciality("");
            setCity("");
            setName("");
          }}
        />
      </Aside>
      <Section>
        <>
          <Pagination
            loading={loading}
            numberOfPosts={sortedList.length}
            currentPage={currentPage}
            postsPerPage={postsPerPage}
            indexOfFirstPost={indexOfFirstPost}
            indexOfLastPost={indexOfLastPost}
            selectPage={(e) => setCurrentPage(+e.target.dataset.key)}
          />
          <DentistList
            paginatedList={paginatedList}
            loading={loading}
            numberOfPosts={sortedList.length}
            currentPage={currentPage}
            postsPerPage={postsPerPage}
          />
          <Pagination
            loading={loading}
            numberOfPosts={sortedList.length}
            currentPage={currentPage}
            postsPerPage={postsPerPage}
            indexOfFirstPost={indexOfFirstPost}
            indexOfLastPost={indexOfLastPost}
            selectPage={(e) => setCurrentPage(+e.target.dataset.key)}
          />
        </>
      </Section>
    </Main>
  );
};

export default FindADentist;
