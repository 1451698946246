import React from "react";
import { Main, Section, Aside } from "../../layout/index";
import { WPRender } from "../../components";
import "./Advertising.css";

const Advertising = () => {
  return (
    <Main slug="advertising" title="ADVERTISING">
      <Aside header="ADVERTISING" showNav={false} />
      <Section>
        <WPRender postType="advertising" slug="advertising" />
      </Section>
    </Main>
  );
};

export default Advertising;
