import React from "react";
import Title from "./Title/Title";
import Article from "./Article/Article";
import styles from "./Main.module.css";

const Main = ({ children, slug, title, bodyClass }) => {
  return (
    <main className={`${styles.main} ${slug}`}>
      <Title slug={slug} title={title} />
      <Article bodyClass={bodyClass}>{children}</Article>
    </main>
  );
};

export default Main;
