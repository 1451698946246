import React from "react";
import { Route, Switch } from "react-router-dom";
import Footer from "./components/Footer/Footer";
import MemberRoutes from "./routes/MemberRoutes";
import GuestRoutes from "./routes/GuestRoutes";
import { Header } from "./components";
import LandingPage from "./pages/LandingPage/LandingPage";
import "./App.css";

const member = [
  "/members",
  "members/emergency-panel",
  "members/leadership",
  "/members/join-renew",
  "/members/member-benefits",
  "/members/member-directory",
  "/members/ce-requirements",
  "/members/ce-courses",
  "/members/cpr-certification",
  "/members/calendar",
  "/members/news",
  "/members/employment-opportunities",
  "/members/practice-opportunities",
  "/members/advertising",
  "/members/foundation",
  "/members/resources",
  "/members/board-and-staff",
  "/members/history",
];

const guest = [
  "/",
  "/board-and-staff",
  "/history",
  "/find-a-dentist",
  "/foundation",
  "/advertising",
  "/employment-opportunities",
  "/practice-opportunities",
  "/fluoridation",
  "/childrens-corner",
  "/emergencies",
  "/privacy-policy",
  "/legal-disclaimer",
];

function App() {
  return (
    <>
      <Switch>
        <Route path="/" component={LandingPage} exact />
        <Route path={member}>
          <Header />
          <MemberRoutes />
        </Route>
        <Route path={guest}>
          <Header />
          <GuestRoutes />
        </Route>
      </Switch>
      <Footer />
    </>
  );
}

export default App;
