import React from "react";
import Select from "react-select";
import "./SearchDentists.css";

const SearchDentists = ({
  speciality,
  selectSpeciality,
  city,
  selectCity,
  name,
  searchName,
  reset,
}) => {
  const specialityOptions = [
    { value: "", label: "All Specialties" },
    { value: "endodontics", label: "Endodontics" },
    { value: "general", label: "General Practice" },
    { value: "maxillofacial", label: "Oral & Maxillofacial Surgery" },
    { value: "orthodontics", label: "Orthodontics" },
    { value: "pediatric", label: "Pediatric Dentistry" },
    { value: "periodontics", label: "Periodontics" },
    { value: "prosthodontics", label: "Prosthodontics" },
  ];

  const cityOptions = [
    { value: "", label: "All Cities" },
    { value: "albany", label: "Albany" },
    { value: "belvedere", label: "Belvedere" },
    { value: "corte", label: "Corte Madera" },
    { value: "fairfax", label: "Fairfax" },
    { value: "foster", label: "Foster City" },
    { value: "greenbrae", label: "Greenbrae" },
    { value: "kentfield", label: "Kentfield" },
    { value: "larkspur", label: "Larkspur" },
    { value: "mill", label: "Mill Valley" },
    { value: "novato", label: "Novato" },
    { value: "reyes", label: "Point Reyes Station" },
    { value: "anselmo", label: "San Anselmo" },
    { value: "francisco", label: "San Francisco" },
    { value: "quentin", label: "San Quentin" },
    { value: "rafael", label: "San Rafael" },
    { value: "rosa", label: "Santa Rosa" },
    { value: "sonoma", label: "Sonoma" },
    { value: "sausalito", label: "Sausalito" },
    { value: "tiburon", label: "Tiburon" },
    { value: "walnut", label: "Walnut Creek" },
  ];

  const inheritValue = (optionsArray, value) => {
    let filteredOptionsArray = optionsArray.filter((option) =>
      option.value === value ? option : null
    );
    return filteredOptionsArray[0];
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 160,
      behavior: "smooth",
    });
  };

  const customStyles = {
    singleValue: (provided) => ({
      ...provided,
      width: "100%",
      textAlign: "center",
    }),
  };

  return (
    <div className="search-dentists">
      <h3 className="search-title">Refine Your Search</h3>
      <Select
        name="speciality-select"
        className="speciality-select"
        styles={customStyles}
        options={specialityOptions}
        value={inheritValue(specialityOptions, speciality)}
        onChange={(inputValue) => {
          selectSpeciality(inputValue.value);
          scrollToTop();
        }}
      />
      <Select
        name="city-select"
        className="city-select"
        options={cityOptions}
        styles={customStyles}
        value={inheritValue(cityOptions, city)}
        onChange={(inputValue) => {
          selectCity(inputValue.value);
          scrollToTop();
        }}
      />
      <form action="" className="name-form">
        <input
          type="text"
          id="last_name"
          placeholder=" Last Name"
          className="name-input"
          value={name}
          onChange={(e) => {
            searchName(e.target.value);
            scrollToTop();
          }}
        />
      </form>
      <p
        className="search-reset-btn"
        onClick={() => {
          reset();
          scrollToTop();
        }}
      >
        Reset
      </p>
    </div>
  );
};

export default SearchDentists;
