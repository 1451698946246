import Phone from "../../../assets/icons/header/Phone";
import Email from "../../../assets/icons/header/Email";
import Instagram from "../../../assets/icons/header/Instagram";
import LinkedIn from "../../../assets/icons/header/LinkedIn";
import styles from "./Social.module.css";

const Social = () => {
  return (
    <div className={styles.social}>
      <a href="tel:4154727974">
        <Phone />
      </a>
      <a
        href="mailto:exec@mcdsweb.org"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Email />
      </a>
      <a
        href="https://instagram.com/marincodentalsociety?utm_medium=copy_link"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Instagram />
      </a>
      <a
        href="https://www.linkedin.com/company/marin-county-dental-society"
        target="_blank"
        rel="noopener noreferrer"
      >
        <LinkedIn />
      </a>
    </div>
  );
};

export default Social;
