import React from "react";

const Pagination = ({
  loading,
  numberOfPosts,
  currentPage,
  postsPerPage,
  indexOfFirstPost,
  indexOfLastPost,
  selectPage,
  propClass,
}) => {
  const numberOfPages = [];

  for (let i = 1; i <= Math.ceil(numberOfPosts / postsPerPage); i++) {
    numberOfPages.push(i);
  }

  return (
    <div className={`pagination ${!propClass ? "" : propClass}`}>
      <ul
        className="pagination-list"
        onClick={(e) => selectPage(e)}
        style={{ color: "grey", fontSize: "15px" }}
      >
        {numberOfPages.map((page) =>
          currentPage === page ? (
            <li
              key={page}
              data-key={page}
              className="pagination-list-page-active"
            >
              {page}
            </li>
          ) : (
            <li key={page} data-key={page} className="pagination-list-page">
              {page}
            </li>
          )
        )}
      </ul>
      {loading ? (
        <span style={{ color: "grey", fontSize: "15px" }}>Loading ...</span>
      ) : (
        <span className="results" style={{ color: "grey", fontSize: "15px" }}>
          {numberOfPosts < 20
            ? `${numberOfPosts} Results`
            : `${
                indexOfFirstPost + 1
              } - ${indexOfLastPost} of ${numberOfPosts}`}
        </span>
      )}
    </div>
  );
};

export default Pagination;
