import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { checkPath } from "../../../utils/checkPath";
import RadioChecked from "../../../assets/icons/radio-checked.png";
import RadioUnChecked from "../../../assets/icons/radio-unchecked.png";
import BannerDr from "../../../assets/banner-smile.jpg";
import Cliff from "../../../assets/cliff-copy.jpg";
import DrWhiteRoom from "../../../assets/dr-white-room-copy.jpg";
import "./HeroBanner.css";

// STOP USE EFFECT ON SMALL SCREEN SIZE!!!!!

const HeroBanner = () => {
  const history = useHistory();
  const location = useLocation();
  const [members, setMembers] = useState();
  const [active, setActive] = useState(1);

  const progressActive = (active) => {
    if (active === 3) return 1;
    return active + 1;
  };

  const handleNav = (path) => {
    members ? history.push(`/members${path}`) : history.push(path);
  };

  useEffect(() => {
    checkPath(location, "members") ? setMembers(true) : setMembers(false);
    let carousel = setInterval(() => {
      setActive(progressActive(active));
    }, 10000);

    return () => clearInterval(carousel);
  }, [active, location]);

  return (
    <section className="hero-banner">
      <img
        src={BannerDr}
        alt="doctor smiling"
        className={`banner-img ${
          active === 1 ? "banner-active" : "banner-inactive"
        }`}
      />
      <img
        src={Cliff}
        alt="marin bay"
        className={`banner-img ${
          active === 2 ? "banner-active" : "banner-inactive"
        }`}
      />
      <img
        src={DrWhiteRoom}
        alt="dental exam"
        className={`banner-img ${
          active === 3 ? "banner-active" : "banner-inactive"
        }`}
      />
      <div className={`${active === 1 ? "progress-bar" : ""}`} />
      <div className={`${active === 2 ? "progress-bar" : ""}`} />
      <div className={`${active === 3 ? "progress-bar" : ""}`} />
      <div className="banner-title-container">
        {active === 1 ? (
          <div className="banner-content">
            <h1 className="banner-title mcds-title">
              MARIN COUNTY
              <br />
              DENTAL SOCIETY
            </h1>
          </div>
        ) : null}
        {active === 2 ? (
          <div className="banner-content">
            <h1 className="banner-title">
              Serving the Needs of our Members and the Community
            </h1>
          </div>
        ) : null}
        {active === 3 ? (
          <div className="banner-content">
            <h1 className="banner-title">
              Founded in 1932
              <br />
              Learn More About the MCDS
            </h1>
            <button className="hero-btn" onClick={() => handleNav("/history")}>
              OUR HISTORY
            </button>
            {members && (
              <button
                className="hero-btn"
                onClick={() => handleNav("/join-renew")}
              >
                MEMBERSHIP
              </button>
            )}
          </div>
        ) : null}
      </div>
      <div className="banner-buttons">
        {active === 1 ? (
          <span onClick={() => setActive(1)}>
            <img src={RadioChecked} alt="radio active" />
          </span>
        ) : (
          <span onClick={() => setActive(1)}>
            <img src={RadioUnChecked} alt="radio inactive" />
          </span>
        )}
        {active === 2 ? (
          <span onClick={() => setActive(2)}>
            <img src={RadioChecked} alt="radio active" />
          </span>
        ) : (
          <span onClick={() => setActive(2)}>
            <img src={RadioUnChecked} alt="radio inactive" />
          </span>
        )}
        {active === 3 ? (
          <span onClick={() => setActive(3)}>
            <img src={RadioChecked} alt="radio active" />
          </span>
        ) : (
          <span onClick={() => setActive(3)}>
            <img src={RadioUnChecked} alt="radio inactive" />
          </span>
        )}
      </div>
    </section>
  );
};

export default HeroBanner;
