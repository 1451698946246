import React from "react";
import ADALogo from "../../assets/ada-square-logo.png";
import CDALogo from "../../assets/cda-square-logo.png";
import TDICLogo from "../../assets/tdic-logo.png";
import styles from "./Affiliates.module.css";

const Affiliates = () => {
  return (
    <div className={styles.container}>
      <h3 className={styles.title}>Proud Member</h3>
      <a href="https://www.cda.org/" rel="noreferrer noopener" target="_blank">
        <img src={CDALogo} alt="CDA logo" />
      </a>
      <a href="https://www.ada.org/" rel="noreferrer noopener" target="_blank">
        <img src={ADALogo} alt="ADA logo" />
      </a>
      <a
        href="https://www.tdicinsurance.com/"
        rel="noreferrer noopener"
        target="_blank"
      >
        <img src={TDICLogo} alt="TDIC logo" />
      </a>
    </div>
  );
};

export default Affiliates;
