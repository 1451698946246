import useFetch from "../../hooks/useFetch";
import Spinner from "../../utils/Spinner/Spinner";
import parse from "html-react-parser";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import styles from "./Posts.module.css";

const Posts = ({ categoryID }) => {
  const { data, loading, error } = useFetch(
    `https://posts.marindentalsociety.org/wp-json/wp/v2/posts?categories=${categoryID}&per_page=10`
  );

  return (
    <>
      {error && <ErrorMessage />}
      {!error && loading && <Spinner />}
      {!error && !loading && !data?.length && (
        <p>There are no posts at this time</p>
      )}
      {!error &&
        !loading &&
        data?.map((post, index) => (
          <div key={index} className={styles.box}>
            <h2>{post.title.rendered}</h2>
            {parse(post.content.rendered)}
          </div>
        ))}
    </>
  );
};

export default Posts;
