import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import ToggleVersion from "./ToggleVersion/ToggleVersion";
import MembersNav from "./MembersNav/MembersNav";
import { checkPath } from "../../utils/checkPath";
import GuestsNav from "./GuestsNav/GuestsNav";
import useSmallScreen from "../../hooks/useSmallScreen";
import Mobile from "./Mobile/Mobile";
import Logo from "../../assets/logo/logo-green.png";
import Social from "./Social/Social";
import styles from "./Header.module.css";

const Header = () => {
  const history = useHistory();
  const location = useLocation();

  const [members, setMembers] = useState(false);
  const [guests, setGuests] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    setMembers(false);
    setGuests(false);
    checkPath(location, "members") ? setMembers(true) : setGuests(true);
    const handleScroll = () => {
      let position = window.pageYOffset;
      position >= 150 ? setScrolled(true) : setScrolled(false);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [location]);

  const { smallScreen } = useSmallScreen(1070);

  return (
    <>
      {smallScreen && <Mobile members={members} />}
      {!smallScreen && (
        <header className={styles.header}>
          <Social />
          <ToggleVersion members={members} />
          <img
            src={Logo}
            alt="logo"
            className={`${styles.logo} ${scrolled && styles.logoScrolled}`}
            onClick={() => {
              members && history.push("/members");
              guests && history.push("/guests");
            }}
          />
          {members && <MembersNav />}
          {guests && <GuestsNav />}
        </header>
      )}
    </>
  );
};

export default Header;
