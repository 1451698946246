import { useState } from "react";
import { Link } from "react-router-dom";
import ExpandMore from "../../../assets/icons/ExpandMore";
import styles from "./GuestsNav.module.css";

const GuestNav = () => {
  const [aboutOpen, setAboutOpen] = useState(false);
  const [classifiedsOpen, setClassifiedsOpen] = useState(false);

  const handleBlur = (e) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setClassifiedsOpen(false);
      setAboutOpen(false);
    }
  };

  return (
    <nav className={styles.nav}>
      <div
        className={styles.submenuContainer}
        onMouseEnter={() => setAboutOpen(true)}
        onMouseLeave={() => setAboutOpen(false)}
        onClick={() => setAboutOpen(!aboutOpen)}
        onFocus={() => setAboutOpen(true)}
        onBlur={handleBlur}
      >
        <Link className={styles.navLink} to="/history">
          About
          <ExpandMore />
        </Link>
        <div
          className={`${styles.submenu} ${
            !aboutOpen ? styles.inactive : styles.aboutActive
          }`}
        >
          <Link to="/board-and-staff" className={styles.submenuLink}>
            Board & Staff
          </Link>
          <Link to="/history" className={styles.submenuLink}>
            History
          </Link>
        </div>
      </div>
      <Link to="/find-a-dentist" className={styles.navLink}>
        Find a Dentist
      </Link>
      <Link to="/foundation" className={styles.navLink}>
        MCDCF
      </Link>
      <Link to="/advertising" className={styles.navLink}>
        Advertising
      </Link>
      <div
        className={styles.submenuContainer}
        onMouseEnter={() => setClassifiedsOpen(true)}
        onMouseLeave={() => setClassifiedsOpen(false)}
        onClick={() => setClassifiedsOpen(!classifiedsOpen)}
        onFocus={() => setClassifiedsOpen(true)}
        onBlur={handleBlur}
      >
        <Link className={styles.navLink} to="/employment-opportunities">
          Classifieds
          <ExpandMore />{" "}
        </Link>
        <div
          className={`${styles.submenu} ${
            !classifiedsOpen ? styles.inactive : styles.classifiedsActive
          }`}
        >
          <Link to="/employment-opportunities" className={styles.submenuLink}>
            Employment Opportunities
          </Link>
          <Link to="/practice-opportunities" className={styles.submenuLink}>
            Practice Opportunities
          </Link>
          <Link to="/other" className={styles.submenuLink}>
            Other
          </Link>
        </div>
      </div>
      <Link to="/fluoridation" className={styles.navLink}>
        Fluoridation
      </Link>
      <Link to="/childrens-corner" className={styles.navLink}>
        Children's Corner
      </Link>
      <Link to="/emergencies" className={styles.navLink}>
        Emergencies
      </Link>
    </nav>
  );
};

export default GuestNav;
