import React from "react";
import { Link } from "react-router-dom";
import FindDentist from "../../../assets/banner-boxes/find-dentist.png";
import CECPR from "../../../assets/banner-boxes/ce-cpr.png";
import JoinRenew from "../../../assets/banner-boxes/join-renew.png";
import Classifieds from "../../../assets/banner-boxes/classifieds.png";
import "./BannerBoxes.css";

const BannerBoxes = () => {
  return (
    <div className="banner-boxes">
      <div className="banner-box">
        <h3 className="box-title">Member Directory</h3>
        <img
          src={FindDentist}
          alt="dental office"
          className="banner-box-icon"
        />
        <p>Browse our roster of Dental Professionals.</p>
        <Link to="/members/member-directory" className="box-btn">
          View Directory
        </Link>
      </div>

      <div className="banner-box">
        <h3 className="box-title">Calendar</h3>
        <img src={CECPR} alt="computer" className="banner-box-icon" />
        <p>Stay up to date on our events.</p>
        <Link to="/members/calendar" className="box-btn">
          View Calendar
        </Link>
      </div>
      <div className="banner-box">
        <h3 className="box-title">Classifieds</h3>
        <img src={JoinRenew} alt="dentist" className="banner-box-icon" />
        <p>Explore opportunities with our members.</p>
        <Link to="/members/employment-opportunities" className="box-btn">
          View Our Listings
        </Link>
      </div>
      <div className="banner-box">
        <h3 className="box-title">News</h3>
        <img
          src={Classifieds}
          alt="page with tooth"
          className="banner-box-icon"
        />
        <p>Get the latest news and updates.</p>
        <Link to="/members/news" className="box-btn">
          View Latest News
        </Link>
      </div>
    </div>
  );
};

export default BannerBoxes;
