import React from "react";
import useFetchEvents from "../../hooks/useFetchEvents";
import parse from "html-react-parser";
import { Main, Aside } from "../../layout/index";
import Spinner from "../../utils/Spinner/Spinner";
import EventsList from "./EventsList/EventsList";
import { ErrorMessage } from "../../components";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid";
import styles from "./Calendar.module.css";

const Calendar = () => {
  const { data, loading, error } = useFetchEvents(
    "https://posts.marindentalsociety.org/wp-json/wp/v2/event?per_page=99",
    10
  );

  return (
    <Main slug="calendar" title="CALENDAR">
      <Aside showNav={false} header="UPCOMING EVENTS">
        <EventsList events={data} loading={loading} />
      </Aside>
      <div className={styles.container}>
        {loading && <Spinner />}
        {error && <ErrorMessage />}
        {!error && !loading && (
          <FullCalendar
            plugins={[dayGridPlugin]}
            initialView="dayGridMonth"
            eventColor="#27a2ad"
            events={data.map((event) => ({
              title: parse(event.title.rendered),
              date: event._event_start_date,
              id: event.id,
              url: event.link,
            }))}
          />
        )}
      </div>
    </Main>
  );
};

export default Calendar;
