import React from "react";
import "./LegalDisclaimer.css";

const LegalDisclaimer = () => {
  return (
    <section className="subpage-content legal-disclaimer">
      <h1 className="subpage-content-title">Legal Information</h1>
      <div className="legal-disclaimer-body">
        <p>
          Thank you for visiting our Web site. Please note that information
          contained on this site is general and not intended to provide specific
          advice. By using this site you agree to all disclaimers and terms of
          use governing this site. Please remember that transmissions on the
          Internet are not always confidential, so you are transmitting
          information at your own risk.
        </p>
        <h2>California Prohibits Non-Competition Agreements</h2>
        <p>
          Employers frequently are unaware that non-competition agreements are
          prohibited under California law. In fact, this is one of the most
          common mistakes employers make. The California Business and
          Professions Code Section 16600 provides that every contract by which
          anyone is restrained from engaging in a lawful profession, trade or
          business of any kind, is void. Courts have construed Section 16600 to
          prohibit non-competition agreements. In fact, California
          non-competition agreements are strictly weighted against the employer.
          California has a strong public policy to ensure employees will not be
          prevented from earning a living if they choose to leave a particular
          job. In addition, California employers who terminate an employee for
          refusing to sign a non-competition agreement may be liable for
          wrongful termination in violation of public policy.
        </p>
      </div>
    </section>
  );
};

export default LegalDisclaimer;
