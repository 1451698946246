import React from "react";
import "./SeasonTicket.css";

const SeasonTicket = () => {
  return (
    <div className="season-ticket-sidebar">
      <h3>Season Ticket</h3>
      <p>
        <a
          href="https://pay.balancecollect.com/m/marincountydentalsociety?amount=390.00"
          target="_blank"
          rel="noopener noreferrer"
        >
          Purchase All Dinner Meetings / CE Courses
          <span className="material-icons">open_in_new</span>
        </a>
      </p>
    </div>
  );
};

export default SeasonTicket;
