import React from "react";
import Logo from "../../../assets/logo/logo-white.png";
import styles from "./WhoWeAre.module.css";

const WhoWeAre = () => {
  return (
    <section className={styles.about}>
      <img src={Logo} alt="logo" className={styles.logo} />
      <div className={styles.articlesWrapper}>
        <article className={styles.who}>
          <h3>Who We Are</h3>
          <p>
            The MCDS is an association of dental professionals serving the
            population of Marin County and is a local component of the
            California Dental Association and the American Dental Association.
          </p>
        </article>
        <article className={styles.mission}>
          <h3>Our Mission</h3>
          <p>
            The Mission of the MCDS is to be the recognized resource for serving
            the needs of its members and of the community.
          </p>
        </article>
      </div>
    </section>
  );
};

export default WhoWeAre;
