import React from "react";
import styles from "./Title.module.css";

const SubpageTitle = ({ slug, title }) => {
  return (
    <header
      className={styles.container}
      style={{ backgroundImage: `url(/header-backgrounds/${slug}.jpg)` }}
    >
      <div className={styles.titleContainer}>
        <div className={styles.titleWrapper}>
          <h1 className={styles.title}>{title}</h1>
        </div>
      </div>
    </header>
  );
};

export default SubpageTitle;
