import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";

const Footer = () => {
  const d = new Date();
  let year = d.getFullYear();
  return (
    <footer className="footer">
      <div className="legal-privacy">
        <Link to="/legal-disclaimer">Legal Disclaimer - </Link>
        <Link to="/privacy-policy">Privacy Policy</Link>
      </div>
      <div className="copyright">
        {`\u00a9 ${year} Marin County Dental Society - All Rights Reserved`}
      </div>
    </footer>
  );
};

export default Footer;
