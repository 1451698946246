import React from "react";
import Board from "../../components/Board/Board";
import Staff from "../../components/Staff/Staff";

const BoardStaffLayout = () => {
  return (
    <div className="board-container" id="board">
      <h2 className="subpage-content-title board-title">Board of Directors</h2>
      <Board />
      <h2 className="subpage-content-title staff-title" id="staff">
        Staff
      </h2>
      <Staff />
    </div>
  );
};

export default BoardStaffLayout;
