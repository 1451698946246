import { useEffect, useState } from "react";
import axios from "axios";
import * as Sentry from "@sentry/react";

const useFetch = (url) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  //Abort is a cleanup to prevent a memory leak in an unmounting component

  useEffect(() => {
    let abort = false;
    setLoading(true);
    axios
      .get(url)
      .then((res) => {
        if (abort) return;
        setData(res.data);
      })
      .catch((err) => {
        setError(true);
        Sentry.captureException(err);
      })
      .finally(() => setLoading(false));
    return () => (abort = true);
  }, [url]);

  return { loading, error, data };
};

export default useFetch;
