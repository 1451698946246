import { useEffect, useState } from "react";

const useSmallScreen = (breakpoint) => {
  const [smallScreen, setSmallScreen] = useState();

  // Check initial window size
  useEffect(() => {
    if (window.innerWidth <= breakpoint) {
      setSmallScreen(true);
    } else if (window.innerWidth > breakpoint) {
      setSmallScreen(false);
    }

    // Check resized window size
    const handleResize = () => {
      if (window.innerWidth <= breakpoint) {
        setSmallScreen(true);
      } else if (window.innerWidth > breakpoint) {
        setSmallScreen(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [breakpoint]);

  return { smallScreen };
};

export default useSmallScreen;
