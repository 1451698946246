import React from "react";
import { Main, Aside, Section } from "../../layout/index";
import WPRender from "../../components/WPRender/WPRender";
import "./Foundation.css";

const Foundation = () => {
  return (
    <Main slug="foundation" title="MARIN COUNTY DENTAL CARE FOUNDATION">
      <Aside header="FOUNDATION" showNav={false} />
      <Section>
        <WPRender postType="foundation" slug="foundation" />
      </Section>
    </Main>
  );
};

export default Foundation;
