import React from "react";
import { Main, Section, Aside } from "../../../layout/index";
import { Posts, MakePost } from "../../../components/index";
import "./Other.css";

const Other = () => {
  const navList = [
    {
      label: "Employment Opportunities",
      value: "employment-opportunities",
      key: 1,
    },
    {
      label: "Practice Opportunities",
      value: "practice-opportunities",
      key: 2,
    },
    {
      label: "Other",
      value: "other",
      key: 3,
    },
  ];

  return (
    <Main slug="employment" title="OTHER">
      <Aside header="CLASSIFIEDS" navList={navList} active={3}>
        <MakePost />
      </Aside>
      <Section>
        <Posts categoryID={50} />
      </Section>
    </Main>
  );
};

export default Other;
